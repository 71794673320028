<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '10px' }
          : { 'padding-bottom': '10px' },
      ]"
      class="body-container"
    >
      <v-container
        v-if="packageInfo && !loading"
        class="px-10 px-md-auto pb-md-0"
      >
        <div>
          <div class="text-h2 text-md-h1 font-weight-bold mx-0">
            {{ packageInfo.name }}
          </div>
          <div class="flex-row mb-4 ml-md-1">
            <span
              class="mr-sm-8 body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <span>
                {{ "Proveedor: " + "&nbsp;" }}
              </span>
              <router-link
                class="black--text text-decoration-underline"
                style="color: inherit"
                :to="getProviderRoute"
              >
                {{ providerName }}
              </router-link>
            </span>
            <span
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon class="mr-2 secondary--text" small> fa-map-pin </v-icon>
              <a
                class="text-decoration-underline mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                style="color: inherit"
                @click="handleLocationModal()"
              >
                {{ packageInfo.city }}, {{ packageInfo.state }}
              </a>
            </span>
          </div>
          <div class="d-inline-block d-sm-flex">
            <Button
              class="my-4 my-sm-0"
              :text="backRoute"
              outlined
              pill
              :height="44"
              prepend-icon="fa-caret-left"
              aria-label="Volver atrás"
              @event="$router.go(-1)"
            />
            <v-spacer></v-spacer>
            <Button
              text="Reservar"
              :height="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 50
              "
              aria-label="Desplzar pantalla al formulario de la reservación"
              @event="scroll('ReservationForm')"
            />
          </div>
        </div>
        <v-divider class="mt-7 mb-14 secondary"></v-divider>
        <div class="mt-3">
          <ImageSlideshow
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :images="formatImagesUrl()"
          />
          <ImageSlideshowTwo v-else :images="formatImagesUrl()" />
          <v-divider class="mb-14 secondary"></v-divider>
          <v-row class="mt-5 mb-16 pb-2">
            <v-col cols="12" sm="6" md="8" class="pr-sm-10 text-body-1">
              <div class="text-h2 font-weight-bold mb-4">
                Descripción del paquete
              </div>
              <div class="text-pre-line">
                {{ packageInfo.description }}
              </div>
              <div class="mt-16">
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.corkage
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.corkage
                        ? "Ofrece descorche para proveedores externos."
                        : "No ofrece descorche para provedores externos."
                    }}
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.extraHour
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.extraHour
                        ? "Ofrece horas extras."
                        : "No ofrece horas extras."
                    }}
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita
                    {{ numberToCurrency(packageInfo.paymentAdvance) }} anticipo
                    para reservar fecha.
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita liquidación del paquete
                    {{
                      packageInfo.paymentDeadline == 0
                        ? "el día del evento."
                        : packageInfo.paymentDeadline +
                          (packageInfo.paymentDeadline == 1
                            ? " día previo al evento."
                            : " días previos al evento.")
                    }}
                  </span>
                </div>
                <div
                  v-if="packageInfo.reimbursement > 0"
                  class="my-7 d-flex d-md-block align-center"
                >
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Reembolso del
                    {{ packageInfo.reimbursement }}% cancelando
                    {{
                      packageInfo.cancellationDeadline == 0
                        ? "el día del evento."
                        : packageInfo.cancellationDeadline +
                          (packageInfo.cancellationDeadline == 1
                            ? " día previo al evento."
                            : " días previos al evento.")
                    }}
                  </span>
                </div>
                <div v-else class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-times-circle
                  </v-icon>
                  <span> No ofrece reembolso. </span>
                </div>
                <div class="mt-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    fas fa-users
                  </v-icon>
                  <span>
                    {{ packageInfo.kidsQuantity }}
                    {{ packageInfo.kidsQuantity === 1 ? "niño." : "niños." }} -
                    {{ packageInfo.adultsQuantity }}
                    {{
                      packageInfo.adultsQuantity === 1 ? "adulto." : "adultos."
                    }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div class="text-h2 font-weight-bold mb-4">
                Desde {{ numberToCurrency(minPrice) }} MXN
              </div>
              <p class="text-h3 font-weight-bold mb-14">¿Qué incluye?</p>
              <div
                v-for="item in packageInfo.packageItems"
                :key="item.id"
                class="d-flex justify-space-between"
              >
                <p class="mb-0">{{ item.name }}</p>
                <p class="mb-0">{{ item.quantity }}</p>
              </div>
              <div class="d-flex justify-space-between">
                <p class="mb-0">Platillos niños</p>
                <p class="mb-0">{{ packageInfo.kidsQuantity }}</p>
              </div>
              <div class="d-flex justify-space-between">
                <p class="mb-0">Platillos adultos</p>
                <p class="mb-0">{{ packageInfo.adultsQuantity }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <ReservationForm
          id="ReservationForm"
          :package-info="packageInfo"
          class="mb-16 pb-9"
        />
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <Footer />
    <ModalLocation
      v-if="packageInfo && !loading"
      :dialog="locationModal"
      :name="providerName"
      :address="getFullAddress()"
      @closeModal="handleLocationModal"
    />
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Footer from "@/components/Shared/Footer.vue";
import ImageSlideshow from "@/components/Shared/ImageSlideshow.vue";
import ImageSlideshowTwo from "@/components/Shared/ImageSlideshowTwo.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import ModalLocation from "@/components/Shared/Modal/ModalLocation.vue";
import Button from "@/components/Shared/Button.vue";
import ReservationForm from "@/components/User/Package/ReservationForm.vue";
import { GET_PACKAGE } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "Package",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    ImageSlideshow,
    ImageSlideshowTwo,
    InlineLoading,
    ModalLocation,
    Button,
    Footer,
    ReservationForm,
  },
  data: () => ({
    providerName: null,
    packageInfo: null,
    loading: false,
    locationModal: false,
    backRoute: null,
    form: {
      eventName: "",
      eventdate: "",
      additionalComment: "",
      selectedSchedule: null,
      availableTimes: "",
      extraHour: false,
      adultsQuantity: 0,
      kidsQuantity: 0,
      extraPerson: 0,
      corkage: false,
      adultDishes: [],
      kidsDishes: [],
      additionalItems: [],
      selectedExtraCapacity: null,
    },
    selectedExtraCapacity: null,
    availableSchedules: [],
    selectedSchedule: null,
    reservationRequest: {},
    minPrice: null,
  }),
  computed: {
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.packageInfo.providerId,
        },
      };
    },
  },
  created() {
    this.fetchSinglePackageInfo();
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    formatImagesUrl() {
      return this.packageInfo.packageImages.map((image) => image.url);
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
    handleLocationModal() {
      this.locationModal = !this.locationModal;
    },
    getFullAddress() {
      return (
        this.packageInfo.address +
        ", " +
        this.packageInfo.city +
        ", " +
        this.packageInfo.state
      );
    },
    async fetchSinglePackageInfo() {
      this.loading = true;
      const packageId = parseInt(this.$route.params.id);
      const { data, errors } = await useQuery(GET_PACKAGE, {
        packageId: packageId,
      });
      if (data) {
        this.packageInfo = data.package;
        this.providerName = data.package.provider.name;
        this.minPrice = this.packageInfo.packagePrices.reduce((min, price) => {
          return price.price < min ? price.price : min;
        }, this.packageInfo.packagePrices[0].price);
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.includes("servicios")) {
        vm.backRoute = "Volver a servicios";
      } else if (from.path.includes("proveedor/")) {
        vm.backRoute = "Volver a proveedor";
      } else {
        vm.backRoute = "Volver";
      }

      next();
    });
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.box-card-shadow {
  box-shadow: 4px 2px 14px 0px rgba(0, 0, 0, 0.35);
}
.btn-background-transparent::before {
  background-color: transparent !important;
}
.circle-btn {
  width: 32px;
  height: 32px;
  min-width: 32px;
}
</style>
